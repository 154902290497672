<section class="card card-custom">
    <div class="card-header">
        <h3 class="card-title">
            {{ 'Add a Epson Web printer' | translate }}
        </h3>
    </div>

    <div class="card-body pt-2">
        <form [formGroup]="form" (ngSubmit)="save()">
            <div class="row mt-5">
                <div class="col-12">
                    <div class="form-group">
                        <label>{{'Device ID' | translate}}</label>
                        <input
                            formControlName="deviceId"
                            type="text"
                            class="form-control"
                            placeholder="{{'Device ID' | translate}}"
                        >
                        <div *ngIf="deviceId.invalid && (deviceId.dirty || deviceId.touched)"
                            class="text-danger">
                            <small *ngIf="deviceId.errors?.required">
                                {{'Device ID is required' | translate}}.
                            </small>
                        </div>
                    </div>
                </div>

                <div class="col-12">
                    <div class="form-group">
                        <label>{{'IP Address' | translate}}</label>
                        <input
                            formControlName="ipAddress"
                            type="text"
                            class="form-control"
                            placeholder="{{'IP Address' | translate}}"
                        >
                        <div *ngIf="ipAddress.invalid && (ipAddress.dirty || ipAddress.touched)" class="text-danger">
                            <small *ngIf="ipAddress.errors?.required">
                                {{'IP Address is required.' | translate}}
                            </small>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
  
    <div class="modal-footer">
        <button (click)="cancel()" type="button" class="btn btn-light" tabindex="-1">
            {{'Cancel' | translate}}
        </button>
        <button (click)="save()" type="submit" class="btn btn-light-success" [disabled]="!form.valid || isSaving">
            {{'Save' | translate}}
        </button>
    </div>
  </section>
  