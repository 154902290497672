export class PrintingData {
  private lines: Line[] = [];
  private endFeed: number = 0;
  private lineWidth: number;

  private alignment: string = Line.LINE_ALIGNMENT_LEFT;
  private bold: boolean;
  private underlined: boolean = false;
  private size: number = 1;

  private callbackFunc: any;

  constructor(lineWidth: number) {
    this.lineWidth = lineWidth;
  }

  public get callback(): any {
    return this.callbackFunc;
  }

  public setCallback(callback: any): PrintingData {
    this.callbackFunc = callback;
    return this;
  }

  public get getLines(): Line[] {
    return this.lines;
  }

  public setAlignment(alignment: string): PrintingData {
    this.alignment = alignment;
    return this;
  }

  public setBold(bold: boolean): PrintingData {
    this.bold = bold;
    return this;
  }

  public setUnderlined(underlined: boolean): PrintingData {
    this.underlined = underlined;
    return this;
  }

  public setSize(size: number): PrintingData {
    this.size = size;
    return this;
  }

  public addLine(text: string): PrintingData {
    this.lines.push(new Line({
        text: text,
        bold: this.bold,
        underlined: this.underlined,
        size: this.size,
        alignment: this.alignment,
        width: this.lineWidth
      },
      this
    ));
    this.lines[this.lines.length - 1].breakTextIfNeeded();
    return this;
  }

  public addJustifiedLine(leftText: string, rightText: string): PrintingData {
    this.lines.push(new JustifiedLine({
        text: leftText,
        rightText: rightText,
        bold: this.bold,
        underlined: this.underlined,
        size: this.size,
        alignment: this.alignment,
        width: this.lineWidth
      },
      this
    ));
    this.lines[this.lines.length - 1].breakTextIfNeeded();
    return this;
  }

  public addBlankLine(): PrintingData {
    this.lines.push(new Line({
        text: '',
        bold: this.bold,
        underlined: false,
        size: this.size,
        alignment: this.alignment,
        width: this.lineWidth
      },
      this
    ));
    return this;
  }

  public addSeparator(): PrintingData {
    this.lines.push(new Line({
        text: this.separatorString,
        bold: this.bold,
        underlined: false,
        size: 1,
        alignment: Line.LINE_ALIGNMENT_CENTER,
        width: this.lineWidth
      },
      this
    ));

    return this;
  }

  public addTestingSeparator(): PrintingData {
    this.lines.push(new Line({
        text: this.testingSeparatorString,
        bold: this.bold,
        underlined: false,
        size: 1,
        alignment: Line.LINE_ALIGNMENT_LEFT,
        width: this.lineWidth
      },
      this
    ));

    return this;
  }

  public get getEndFeed(): number {
    return this.endFeed;
  }

  public setEndFeed(endFeed: number): PrintingData {
    this.endFeed = endFeed;
    return this;
  }

  private get separatorString(): string {
    return Array(this.lineWidth).fill('-').join('');
  }

  private get testingSeparatorString(): string {
    let separator = '';

    let j = 1;

    for (let i = 1; i <= this.lineWidth; i++) {
      separator += i % 10 == 0 ? j++ * 10 : '-';
      if (i % 10 == 0) {
        i++;
      }
    }

    return separator;
  }
}

export class Line {
  public static readonly LINE_ALIGNMENT_LEFT = 'left';
  public static readonly LINE_ALIGNMENT_CENTER = 'center';
  public static readonly LINE_ALIGNMENT_RIGHT = 'right';

  alignment: string;
  text: string;
  bold: boolean;
  underlined: boolean;
  size: number;
  width: number;

  private printingData: PrintingData;

  constructor(line: any, printingData: PrintingData) {
    this.alignment = line?.alignment;
    this.text = line.text;
    this.bold = line?.bold;
    this.underlined = line?.underlined;
    this.size = line?.size;
    this.width = line?.width;

    this.printingData = printingData;
  }

  public breakTextIfNeeded(): void {
    if (this.text.length > this.lineWidth) {
      if (!this.isOneWord()) {
        let exceededText = this.adjustAndReturnExceededText();
        this.printingData.addLine(exceededText);
        this.printingData.getLines[this.printingData.getLines.length - 1].breakTextIfNeeded();
      }
    }
  }

  private adjustAndReturnExceededText() {
    let words = this.text.split(' ');

    let i = 0;

    let newText = '';

    while (newText.length + words[i].length < this.lineWidth) {
      newText += words[i] + ' ';
      i++;
    }

    this.text = newText;

    let exceededText = '';

    while (i < words.length) {
      exceededText += words[i] + ' ';
      i++;
    }

    return exceededText;
  }

  private isOneWord(): boolean {
    return this.text.lastIndexOf(' ') === -1;
  }

  public getText(): string {
    return this.text;
  }

  protected get lineWidth(): number {
    return this.width / this.size;
  }
}

export class JustifiedLine extends Line {

  private rightText: string;

  constructor(line: any, printingData: PrintingData) {
    super(line, printingData);
    this.rightText = line?.rightText;
  }

  public getText(): string {
    let lines: string[] = [];
    let leftText = this.text;
    let rightText = this.rightText;

    while (leftText.length + rightText.length > this.lineWidth) {
        let availableSpace = this.lineWidth - rightText.length;
        let linePart = leftText.substring(0, availableSpace);

        lines.push(linePart);
        leftText = leftText.substring(availableSpace);

        if (leftText.length === 0) {
          break;
        }
    }

    let spaceCount = Math.max(0, this.lineWidth - leftText.length - rightText.length);
    lines.push(leftText + ' '.repeat(spaceCount) + rightText);

    return lines.join('\n');
  }

}
