<div class="custom no-gutters w-100 input-group">
    <div class="w-100" ngbDropdown container="body" role="group">
        <button
            *ngIf="(devices.length + printers.length) > 1"
            [ngClass]="buttonClass"
            class="btn btn-icon btn-lg btn-light col-auto"
            ngbDropdownToggle
            [disabled]="disabled"
        >
            <ng-container *ngTemplateOutlet="content"></ng-container>
        </button>
        <button
            *ngIf="(devices.length + printers.length) <= 1"
            [ngClass]="buttonClass"
            class="btn btn-icon btn-lg btn-light col-auto"
            [disabled]="disabled"
            (click)="handlePrint()"
        >
            <ng-container *ngTemplateOutlet="content"></ng-container>
        </button>
        <div class="dropdown-menu w-auto position-absolute" ngbDropdownMenu>
            <div class="menu-item px-3 my-0" *ngFor="let device of devices">
                <button
                    ngbDropdownItem
                    class="menu-link px-3 py-2"
                    (click)="handleChooseServerPrinter(device)"
                    [disabled]="disabled"
                >
                    <span class="menu-title">{{ device.name }}</span>
                </button>
            </div>
            <div class="menu-item px-3 my-0" *ngFor="let printer of printers">
                <button
                    ngbDropdownItem
                    class="menu-link px-3 py-2"
                    (click)="handleChosePrinter(printer)"
                    [disabled]="disabled"
                >
                    <span class="menu-title">{{ printer.deviceName }}</span>
                </button>
            </div>
        </div>
    </div>
</div>

<ng-template #content>
    <ng-content></ng-content>
</ng-template>
  
