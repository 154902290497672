<section class="card card-custom">
    <div class="card-header">
        <h3 class="card-title">
            <i class="fal fa-exclamation-triangle mr-2 text-warning"></i>
            <span>{{ 'CERTIFICATE_WARNING.SECURITY_WARNING' | translate }}</span>
        </h3>
    </div>

    <div class="card-body pt-6">
        <p>
          {{ 'CERTIFICATE_WARNING.WRONG_PARAMS' | translate }}
        </p>
        <p>
          {{ 'CERTIFICATE_WARNING.NOT_USING_CERTIFICATE' | translate }}
        </p>
    
        <h5>{{ 'CERTIFICATE_WARNING.HOW_TO_ENABLE_CERTIFICATE' | translate }}</h5>
        <ol>
          <li>{{ 'CERTIFICATE_WARNING.OPEN_PRINTERS_IP_ADDRESS' | translate }}</li>
          <li>{{ 'CERTIFICATE_WARNING.GO_TO_ADVANCED_SETTINGS' | translate }}</li>
          <li>{{ 'CERTIFICATE_WARNING.GO_TO_SSL_CERTIFICATES' | translate }}</li>
          <li>{{ 'CERTIFICATE_WARNING.CHOOSE_DOWNLOAD' | translate }}</li>
          <li>
            {{ 'CERTIFICATE_WARNING.DOWNLOAD_NOT_AVAILABLE' | translate }}
            <ul>
              <li>{{ 'CERTIFICATE_WARNING.ENTER_COMMON_NAME' | translate }}</li>
              <li>{{ 'CERTIFICATE_WARNING.CLICK_NOT_SECURE' | translate }}</li>
              <li>{{ 'CERTIFICATE_WARNING.SELECT_DETAILS' | translate }}</li>
              <li>{{ 'CERTIFICATE_WARNING.EXPORT' | translate }}</li>
            </ul>
          </li>
          <li>
            {{ 'CERTIFICATE_WARNING.INSTALL_CERTIFICATE' | translate }}
            <ul>
              <li>{{ 'CERTIFICATE_WARNING.INSTALL_ON_WINDOWS' | translate }}</li>
              <li>{{ 'CERTIFICATE_WARNING.INSTALL_ON_MAC' | translate }}</li>
            </ul>
          </li>
          <li>{{ 'CERTIFICATE_WARNING.RESTART_BROWSER' | translate }}</li>
        </ol>
    
        <p>
          {{ 'CERTIFICATE_WARNING.CONNTACT' | translate }}
        </p>
      </div>
  
    <div class="modal-footer">
        <button (click)="cancel()" type="button" class="btn btn-light" tabindex="-1">
            {{'Ok' | translate}}
        </button>
    </div>
  </section>
  
