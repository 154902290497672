<section class="card card-custom">
    <div class="card-header py-6">
        <h3 class="card-title">
            {{'Printer configuration' | translate}}
        </h3>
        <div class="card-toolbar">
            <div class="d-flex align-items-center justify-content-between">
                <h6 class="m-0 mr-4">{{'Add a printer:' | translate}}</h6>
                <div class="btn-group" role="group">
                    <button (click)="connectUsbPrinter()" type="button" class="btn btn-outline-secondary" tabindex="-1">
                        <i class="fab fa-usb p-0"></i>
                        <span class="d-none d-md-inline-block ml-2">{{'USB' | translate}}</span>
                    </button>
                    <button (click)="connectBixolonWebPrinter()" type="button" class="btn btn-outline-secondary"
                            tabindex="-1">
                        <i class="fal fa-ethernet"></i>/
                        <i class="fal fa-wifi p-0"></i>
                        <span class="d-inline d-md-none ml-2">Bixolon</span> 
                        <span class="d-none d-md-inline ml-2">{{ 'Bixolon Web Print' | translate }}</span>
                    </button>
                    <button (click)="connectEpsonWebPrinter()" type="button" class="btn btn-outline-secondary" tabindex="-1">
                        <i class="fal fa-ethernet"></i> /
                        <i class="fal fa-wifi p-0"></i>
                        <span class="d-inline d-md-none ml-2">Epson</span> 
                        <span class="d-none d-md-inline ml-2">{{ 'Epson Web Print' | translate }}</span>
                    </button>
                    <button (click)="connectServerPrinter()" type="button" class="btn btn-outline-secondary"
                            tabindex="-1">
                        <i class="fal fa-server p-0"></i>
                        <span class="d-none d-md-inline-block ml-2">{{'Server Print' | translate}}</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div class="card-body pt-2">
        <h6 *ngIf="printers.length == 0 && devices.length == 0"
            class="text-center pt-5 mt-5 mb-2">{{'No connected printers' | translate}}</h6>
        <p *ngIf="printers.length == 0 && devices.length == 0"
           class="text-center text-muted mb-5">{{'Add a printer using one of above methods'}}</p>
        <h6 *ngIf="printers.length != 0" class="my-6">{{'List of saved printers' | translate}}</h6>
        <ul *ngIf="printers.length != 0" class="list-group">
            <li class="row no-gutters list-group-item d-flex text-uppercase">
                <div class="col-6">
                    {{'Device' | translate}}
                </div>
                <div class="col-2 text-center">
                    {{'Line Width' | translate}}
                </div>
                <div class="col-4 text-right">
                    {{'Action' | translate}}
                </div>
            </li>
            <li *ngFor="let printer of printers" class="row no-gutters list-group-item d-flex align-items-center">
                <div class="col-6">
                    <p class="m-0">{{printer?.deviceName}}</p>
                </div>
                <div class="col-2">
                    <input type="number" min="30" max="100" class="form-control" [(ngModel)]="printer.lineWidth"
                           (ngModelChange)="lineWidthChanged(printer)"
                           placeholder="{{'Line Width' | translate}}">
                </div>
                
                <div class="col-4 text-nowrap text-right pr-0 d-flex align-items-center justify-content-end flex-wrap">
                    <button type="button" (click)="testPrint(printer)" class="btn btn-sm btn-light mr-2">
                        <i class="fal fa-print"></i>
                        <span class="d-none d-md-inline-block">{{'Test print' | translate}}</span>
                    </button>
                    <div class="d-flex flex-column align-items-center">
                        <button *ngIf="printer.printerType === 'epson_web_printer'" 
                                type="button" 
                                (click)="connectEpsonWebPrinter(printer)"
                                ngbTooltip="{{'Edit Printer' | translate}}"
                                class="btn btn-hover-transparent-primary btn-sm btn-icon">
                            <i class="fal fa-pen"></i>
                        </button>
                
                        <button type="button" 
                                (click)="removePrinter(printer)"
                                ngbTooltip="{{'Remove Printer' | translate}}"
                                class="btn btn-hover-transparent-danger btn-sm btn-icon mt-1">
                            <i class="fal fa-times"></i>
                        </button>
                    </div>
                </div>
            </li>
        </ul>
        <h6 *ngIf="devices.length != 0" class="my-6">{{'List of saved server printers' | translate}}</h6>
        <ul *ngIf="devices.length != 0" class="list-group">
            <li class="row no-gutters list-group-item d-flex text-uppercase">
                <div class="col-4">
                    {{'Device' | translate}}
                </div>
                <div class="col-4">
                    {{'Serial number' | translate}}
                </div>
                <div class="col-3">
                    {{'Language' | translate}}
                </div>
                <div class="col-1 text-right">
                    {{'Action' | translate}}
                </div>
            </li>
            <li *ngFor="let device of devices" class="row no-gutters list-group-item d-flex align-items-center">
                <div class="col-4">
                    <p class="m-0 mr-2">{{device?.name}}</p>
                    <p class="m-0 text-muted mr-2">{{device?.description}}</p>
                </div>
                <div class="col-4">
                    <p class="m-0 mr-2">{{device?.serialNumber}}</p>
                </div>
                <div class="col-3">
                    <span class="symbol symbol-20 mr-2">
                        <img src="{{getLanguageFlag(device?.language)}}" alt="{{device?.language}}"/>
                    </span>
                </div>
                <div class="col-1 text-nowrap text-right pr-0">
                    <button type="button" (click)="connectServerPrinter(device)"
                            ngbTooltip="{{'Edit Printer' | translate}}"
                            class="btn btn-hover-transparent-primary btn-sm btn-icon float-right"
                            tabindex="-1">
                        <i class="fal fa-pen"></i>
                    </button>
                    <button type="button" (click)="removeDevice(device)"
                            ngbTooltip="{{'Remove Printer' | translate}}"
                            class="btn btn-hover-transparent-danger btn-sm btn-icon float-right"
                            tabindex="-1">
                        <i class="fal fa-times"></i>
                    </button>
                </div>
            </li>
        </ul>
    </div>
    <div class="modal-footer">
        <button type="button" (click)="cancel()" class="btn btn-light">
            {{'Close' | translate}}
        </button>
    </div>
</section>
