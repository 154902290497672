import { 
  Component, 
  EventEmitter, 
  Input, 
  OnInit, 
  Output 
} from '@angular/core';
import { DeviceModel } from '../../models/device.model';
import { Printer } from '../../printing/printer';

@Component({
  selector: 'app-printer-selector',
  templateUrl: './printer-selector.component.html',
  styleUrls: ['./printer-selector.component.scss']
})
export class PrinterSelectorComponent implements OnInit {
  @Input() title: string;
  @Input() devices: DeviceModel[] = [];
  @Input() printers: Printer[] = [];
  @Input() buttonClass: string = '';
  @Input() disabled: boolean = false;
  @Output() choosePrinter = new EventEmitter<any>();
  @Output() chooseServerPrinter = new EventEmitter<DeviceModel>();

  constructor() { }

  ngOnInit(): void {
  }

  handleChooseServerPrinter(device: any) {
    this.chooseServerPrinter.emit(device);
  }

  handleChosePrinter(printer: any) {
    this.choosePrinter.emit(printer);
  }

  handlePrint(): void {
    if(this.devices.length + this.printers.length === 1) {
      if(this.devices?.length) {
        this.handleChooseServerPrinter(this.devices[0]);
      }
      if(this.printers?.length) {
        this.handleChosePrinter(this.printers[0]);
      }
    }
  }

}
