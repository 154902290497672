import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-certificate-warning-modal',
  templateUrl: './certificate-warning-modal.component.html',
  styleUrls: ['./certificate-warning-modal.component.scss']
})
export class CertificateWarningModalComponent implements OnInit {
  constructor(
    private activeModal: NgbActiveModal,
  ) {}

  ngOnInit(): void {}

  dismiss() {
    this.activeModal.dismiss();
  }

  cancel() {
    this.activeModal.close();
  }
}
