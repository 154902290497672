import {Component, EventEmitter, OnInit} from '@angular/core';
import {AbstractControl, FormControl, FormGroup, Validators} from '@angular/forms';
import {LANGUAGES} from '../../globals';
import {UserService} from '../../services/user.service';
import {UserModel} from '../../models/user.model';
import {DeviceModel} from '../../models/device.model';
import {DeviceService} from '../../services/device.service';

@Component({
    selector: 'app-add-server-printer-modal',
    templateUrl: './add-server-printer-modal.component.html',
    styleUrls: ['./add-server-printer-modal.component.scss']
})
export class AddServerPrinterModalComponent implements OnInit {
    languages = LANGUAGES;
    selectedLanguage: any;

    user: UserModel;
    restaurantId: number;

    form: FormGroup;
    isSaving = false;
    saved = new EventEmitter<boolean>();

    deviceType = 'server-printer';
    device: DeviceModel;
    deviceId: number;

    constructor(private userService: UserService,
                private deviceService: DeviceService,
    ) {
        this.userService.user.subscribe((user) => {
            if (user) {
                this.user = user;
                this.restaurantId = user.restaurantId;
            }
        });
    }

    ngOnInit(): void {
        this.initForm();

        if (this.device) {
            this.selectedLanguage = LANGUAGES.find(language => language.lang === (this.device.language || 'de'));
            this.deviceId = this.device.id;
        }
    }

    cancel() {
        this.saved.emit(false);
    }

    private initForm(): void {
        this.form = new FormGroup({
            name: new FormControl(this.device?.name ? this.device.name : '', Validators.required),
            serialNumber: new FormControl(this.device?.serialNumber ? this.device.serialNumber : '', Validators.required),
            autoPrint: new FormControl(this.device?.autoPrint ?? false), 
            language: new FormControl(this.device?.language ? this.device.language : 'de', Validators.required),
            description: new FormControl(this.device?.description ? this.device.description : ''),
            restaurantId: new FormControl(this.restaurantId ?? ''),
            deviceType: new FormControl(this.deviceType ?? ''),
        });
    }

    get name(): AbstractControl {
        return this.form.get('name');
    }

    get serialNumber(): AbstractControl {
        return this.form.get('serialNumber');
    }

    get autoPrint(): AbstractControl {
        return this.form.get('autoPrint');
    }

    get language(): AbstractControl {
        return this.form.get('language');
    }

    get description(): AbstractControl {
        return this.form.get('description');
    }

    save() {
        if (this.form.valid) {
            this.isSaving = true;

            if (this.deviceId) {
                this.device = new DeviceModel(this.form.value);
                this.deviceService.updateDevice(this.device, this.deviceId, 'Device successfully updated.').subscribe((response: any) => {
                    if (response) {
                        const index = this.deviceService.devices.findIndex(device => device.id === this.deviceId);
                        if (index !== -1) {
                            this.deviceService.devices[index] = response.data[0];
                        }

                        this.cancel();
                        this.isSaving = false;
                        this.saved.emit(true);
                    }
                });
            } else {
                this.device = new DeviceModel(this.form.value);
                this.deviceService.createDevice(this.device, 'Device successfully saved.').subscribe((response: any) => {
                    if (response) {
                        this.deviceService.devices.push(response.data[0]);

                        this.cancel();
                        this.isSaving = false;
                        this.saved.emit(true);
                    }
                });
            }


        }
    }

    handleLanguageSelection(language: any): void {
        this.selectedLanguage = language;
        this.language.setValue(language.lang);
    }
}
