import { Component, OnInit, EventEmitter } from '@angular/core';
import { EpsonWebPrinter } from '../../printing/printers/epson.web.printer';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { PrinterService } from '../../services/printer.service';

@Component({
  selector: 'app-epson-web-printer-modal',
  templateUrl: './epson-web-printer-modal.component.html',
  styleUrls: ['./epson-web-printer-modal.component.scss']
})
export class EpsonWebPrinterModalComponent implements OnInit{
  printer: EpsonWebPrinter;
  printerDeviceId: string;

  saved = new EventEmitter<EpsonWebPrinter>();

  form: FormGroup;

  isSaving = false;

  constructor(
    private activeModal: NgbActiveModal,
    private translate: TranslateService,
    private printerService: PrinterService,
  ) {
    this.printer = new EpsonWebPrinter();
  }

  ngOnInit(): void {
    this.initForm();

    if (this.printer) {
      this.printerDeviceId = this.printer.deviceId
    }
  }

  dismiss() {
    this.activeModal.dismiss();
    this.saved.emit(undefined);
  }

  cancel() {
    this.activeModal.close();
  }

  save() {
    if (this.form.valid) {
      this.isSaving = true;

      if (this.printerDeviceId) {
        this.printer.deviceId = this.deviceId.value;
        this.printer.ipAddress = this.ipAddress.value;
        this.printerService.savePrinters();

        this.cancel();
        this.isSaving = false;
        this.saved.emit(this.printer);
      } else {
        this.printer = new EpsonWebPrinter(undefined, {
          deviceId: this.deviceId.value,
          ipAddress: this.ipAddress.value,
        });

        this.printerService.addPrinter(this.printer);

        this.cancel();
        this.isSaving = false;
        this.saved.emit(this.printer);
      }
    }
  }

  get deviceId(): AbstractControl {
    return this.form.get('deviceId') as FormControl;
  }

  get ipAddress(): AbstractControl {
    return this.form.get('ipAddress') as FormControl;
  }

  private initForm(): void {
    this.form = new FormGroup({
      deviceId: new FormControl(this.printer.deviceId, Validators.required),
      ipAddress: new FormControl(this.printer.ipAddress, Validators.required),
    });
  }

  translateLabel(value: string) {
    return this.translate.instant(value);
  }
}
