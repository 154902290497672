import { Printer } from '../printer';
import { PrintingData } from '../printing-data';
import { PrinterService } from '../../services/printer.service';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

declare const epson: any;

export class EpsonWebPrinter extends Printer {
  static readonly PRINTER_TYPE = 'epson_web_printer';

  private ePos: any;
  private builder: any;
  private devObj: any;

  constructor(printerService?: PrinterService, printer?: any) {
    super(printerService, printer);

    this.ipAddress = printer?.ipAddress;
    this.printerType = EpsonWebPrinter.PRINTER_TYPE;

    if (this.printerService) {
      this.connect().catch(error => {
        if (this.printerService) {
          this.printerService.showErrorMessage(this.deviceName, error.message);
        }
      });
    }
  }

  getUrl = (): string => {
		const protocol = window.location.protocol;
    return `${protocol}//${this.ipAddress}/cgi-bin/epos/service.cgi?devid=${this.deviceId}`;
  }

  getPort = (): number => {
		const protocol = window.location.protocol;
    return protocol === 'https:' ? 8043 : 8008;
  }

  async connect(): Promise<void> {
    try {
      const ePosDev = new epson.ePOSDevice();

      const connectionData = await this.connectToPrinter(ePosDev, this.ipAddress, this.getPort());
      if (connectionData === 'OK') {
        await this.createDevice(ePosDev);
      } else {
        throw new Error(`Failed to connect: ${connectionData}`);
      }
    } catch (error) {
      console.error('Error while connecting to printer:', error);
    }
  }

  private connectToPrinter(ePosDev: any, printerIPAddress: string, printerPort: number): Promise<string> {
    return new Promise((resolve, reject) => {
      ePosDev.connect(printerIPAddress, printerPort, (data: string) => {
        if (data === 'OK') {
          this.setIsPrintSecure(true)
          resolve(data);
        } else {
          this.setIsPrintSecure(false)
          if (this.printerService && data === 'ERROR_PARAMETER') {
            this.printerService.openSecurityWarningModal();
          }
          reject(data);
        }
      }, { eposprint: true});
    });
  }

  private createDevice(ePosDev: any): Promise<void> {
    return new Promise((resolve, reject) => {
      ePosDev.createDevice(
        this.deviceId,
        ePosDev.DEVICE_TYPE_PRINTER,
        { crypto: true, buffer: false },
        (devobj: any, retcode: string) => {
          this.devObj = devobj;
          if (retcode === 'OK') {
            resolve();
          } else {
            reject(`Failed to create device: ${retcode}`);
          }
        }
      );
    });
  }

  setIsPrintSecure(value: boolean) {
    let newValue = value;

    this.isPrintSecure = newValue;
    if(this.printerService) this.printerService.savePrinters();
  }

  onReceive(res: any) {
    if (!res.success) {
      if (this.printerService) {
        this.printerService.showErrorMessage(this.deviceName, 'Print error occured. ');
      }
    }
  }

  onError(err: any) {
    if (this.printerService) {
      this.printerService.showErrorMessage(this.deviceName, 'Error while sending data to printer');
    }
  }

  printCommand = () => {
    const url = this.getUrl();
    const epos = new epson.ePOSPrint(url);

    epos.onreceive = this.onReceive.bind(this);
    epos.onerror = this.onError.bind(this);;

    return epos;
  }

  async printData(): Promise<void> {
    if (!this.printQueue) {
      this.currentlyPrinting = false;
      return;
    }

    let data = this.printQueue.shift();

    if (!data) {
      this.currentlyPrinting = false;
      return;
    }

    this.ePos = this.printCommand();
    this.builder = new epson.ePOSBuilder();

    this.transformPrintData(data);

    this.builder.addFeedLine(data.getEndFeed || 5);
    this.builder.addCut(this.builder.CUT_FEED);
    this.ePos.send(this.builder.toString());

    this.printData();
  }

  protected transformPrintData(data: PrintingData): void {
    data.getLines.forEach(line => {
      this.builder.addTextSize(line.size, line.size);
      this.builder.addTextStyle(false, line.underlined ? 1 : 0, line.bold ? 1 : 0);
      this.builder.addTextAlign(line.alignment);
      this.builder.addText(line.getText() + '\n');
    });
  }

  get deviceName(): string {
    return this.deviceId;
  }

  toJSON() {
    return {
      ...this,
      printerService: null,
      ePos: null,
      builder: null,
      devObj: null,
    };
  }
}
