import { PrintingData } from './printing-data';
import { PrinterService } from '../services/printer.service';
import { PRODUCT_TYPE } from '../globals';

export abstract class Printer {

  static readonly MIN_LINE_WIDTH = 35;

  protected printerService: PrinterService;

  printerType: string;

  manufacturer: string;
  model: string;
  vendorId: number;
  productId: number;
  serialNumber: string;
  logicalName: string;
  deviceId: string;
  ipAddress: string;
  port: number;
  lineWidth: number;
  connected: boolean;
  isPrintSecure: boolean;

  productType: string;

  protected currentlyPrinting: boolean = false;

  protected printQueue: PrintingData[] = [];

  constructor(printerService?: PrinterService, printer?: any) {
    this.printerType = printer?.printerType;
    this.manufacturer = printer?.manufacturer;
    this.model = printer?.model;
    this.vendorId = printer?.vendorId;
    this.productId = printer?.productId;
    this.serialNumber = printer?.serialNumber;
    this.logicalName = printer?.logicalName;
    this.deviceId = printer?.deviceId;
    this.ipAddress = printer?.ipAddress;
    this.port = printer?.port;
    this.lineWidth = printer?.lineWidth || Printer.MIN_LINE_WIDTH;
    this.connected = false;

    this.productType = printer?.productType || '';

    this.printerService = printerService;
  }

  abstract async connect(): Promise<void>;

  print(data: PrintingData): void {
    if (!data) {
      return;
    }

    this.printQueue.push(data);

    if (!this.currentlyPrinting) {
      this.currentlyPrinting = true;
      this.printData();
    }
  };

  protected async abstract printData(): Promise<void>;

  protected abstract transformPrintData(data: PrintingData): void;

  protected changeConnectionStatus(connected: boolean) {
    let changed = this.connected != connected;
    this.connected = connected;
    if (this.printerService && changed) {
      this.printerService.onPrinterConnectionStatusChanged();
    }
  }

  get deviceName(): string {
    return this.manufacturer + ' ' + this.model;
  }

  get isPrintingFood(): boolean {
    return this.productType == PRODUCT_TYPE.food;
  }

  get isPrintingBeverages(): boolean {
    return this.productType == PRODUCT_TYPE.beverage;
  }

  get isPrintingFoodAndBeverages(): boolean {
    return this.productType == '';
  }

  toJSON() {
    return {...this, printerService: null, printQueue: null,};
  }

}
